import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import colors from "../utils/colors"
import {
  outerStyles,
  innerStyles,
  LinkToQuiz,
  Subtitle,
  mobileWidth,
} from "../utils/common-styles"
import dev from "../images/svg/dev.svg"

const ContainerFooter = styled.footer`
  ${outerStyles}
  background: ${colors.BLUE};
`

const ContainerNavigation = styled.section`
  ${outerStyles}
  background: ${colors.DARK_BLUE};
`

const ContainerStartQuiz = styled.section`
  ${outerStyles}
  background: ${colors.BLUE};
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const InnerNavigation = styled.div`
  ${innerStyles}
  padding: 40px 20px;
`

const InnerStartQuiz = styled.div`
  ${innerStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
`

const HomePageLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  text-decoration: none;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  padding: 5px 0;
`

const DevIcon = styled.img`
  margin: -11px 5px -11px 0;
  height: 30px;
`

const Copyright = styled.div`
  line-height: 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.GRAY_TEXT};
  margin-left: 30px;
  @media (max-width: ${mobileWidth}px) {
    margin-left: 0;
    text-transform: none;
  }
`

const Title = styled.h2`
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: ${colors.WHITE};
  margin-bottom: 60px;
`

const TitleStartQuiz = styled(Title)`
  margin-bottom: 20px;
`

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${mobileWidth}px) {
    justify-content: stretch;
  }
`

const NavGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  @media (max-width: ${mobileWidth}px) {
    margin-bottom: 30px;
  }
  &:last-child {
    margin-right: 0;
  }
`

const NavTitle = styled.h4`
  width: 180px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.WHITE};
  margin-bottom: 20px;
`

const NavLink = styled(Link)`
  color: ${colors.GREEN};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
`

const PrivacyLink = styled(Link)`
  line-height: 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.GRAY_TEXT};
  margin-left: 30px;
  @media (max-width: ${mobileWidth}px) {
    margin-left: 0;
    text-transform: none;
  }
`

const Footer = () => (
  <>
    <ContainerStartQuiz>
      <InnerStartQuiz>
        <TitleStartQuiz>
          Entdecken Sie in unter 30 Sekunden die beste Website, um den richtigen Entwickler zu finden
        </TitleStartQuiz>
        <Subtitle>
          Wir stellen Ihnen 10 kurze Fragen, um Ihre Anforderungen besser zu verstehen, und geben Ihnen eine persönliche Empfehlung
        </Subtitle>
        <LinkToQuiz to="/quiz/">Quiz starten</LinkToQuiz>
      </InnerStartQuiz>
    </ContainerStartQuiz>
    <ContainerNavigation>
      <InnerNavigation>
        <Title>Entwickler finden und engagieren</Title>
        <NavLinks>
          <NavGroup>
          <NavTitle>Navigation</NavTitle>
            <NavLink to="/blog/">Blog</NavLink>
            <NavLink to="/contact/">Kontakt</NavLink>
          </NavGroup>
          <NavGroup>
            <NavTitle>Backend-Entwickler</NavTitle>
            <NavLink to="/symfony-entwickler/">Symfony-Entwickler</NavLink>
            <NavLink to="/laravel-entwickler/">Laravel-Entwickler</NavLink>
            <NavLink to="/node-entwickler/">Node.js-Entwickler</NavLink>
            <NavLink to="/php-entwickler/">PHP-Entwickler</NavLink>
            <NavLink to="/api-entwickler/">API-Entwickler</NavLink>
            <NavLink to="/cpp-entwickler/">C++-Entwickler</NavLink>
            <NavLink to="/dotnet-entwickler/">.NET-Entwickler</NavLink>
            <NavLink to="/csharp-entwickler/">C#-Entwickler</NavLink>
            <NavLink to="/python-entwickler/">Python-Entwickler</NavLink>
          </NavGroup>
          <NavGroup>
            <NavTitle>Frontend-Entwickler</NavTitle>
            <NavLink to="/vue-entwickler/">Vue.js-Entwickler</NavLink>
            <NavLink to="/react-entwickler/">React.js-Entwickler</NavLink>
            <NavLink to="/nuxt-entwickler/">Nuxt-Entwickler</NavLink>
            <NavLink to="/gatsby-js-entwickler/">Gatsby.js-Entwickler</NavLink>
            <NavLink to="/next-js-entwickler/">Next.js-Entwickler</NavLink>
            <NavLink to="/angular-entwickler/">Angular-Entwickler</NavLink>
            <NavLink to="/jquery-entwickler/">jQuery-Entwickler</NavLink>
          </NavGroup>
          <NavGroup>
            <NavTitle>App-Entwickler</NavTitle>
            <NavLink to="/flutter-entwickler/">Flutter-Entwickler</NavLink>
            <NavLink to="/react-native-entwickler/">React Native-Entwickler</NavLink>
            <NavLink to="/ionic-entwickler/">Ionic-Entwickler</NavLink>
            <NavLink to="/ios-entwickler/">iOS-Entwickler</NavLink>
            <NavLink to="/android-entwickler/">Android-Entwickler</NavLink>
            <NavLink to="/quasar-entwickler/">Quasar-Entwickler</NavLink>
          </NavGroup>
        </NavLinks>
      </InnerNavigation>
    </ContainerNavigation>
    <ContainerFooter>
      <Inner>
        <HomePageLink to="/">
          <DevIcon src={dev} alt="" />
          <span>Softwareentwicklerfinden.de</span>
        </HomePageLink>
        <Copyright>
          © {new Date().getFullYear()}, SOFTWAREENTWICKLERFINDEN.DE
        </Copyright>
        <PrivacyLink to="/policy/">INTEGRITETSPOLICY</PrivacyLink>
      </Inner>
    </ContainerFooter>
  </>
)

export default Footer
