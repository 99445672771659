import React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import "typeface-muli"

import Header from "./header"
import Footer from "./footer"
import StartQuizInfo from "./start-quiz-info"
import GetStartedInfo from "./get-started-info"
import GlobalStyles from "./global-styles.js"
//import { outerStyles, innerStyles } from "../utils/common-styles"
import { innerStyles } from "../utils/common-styles"

const Container = styled.div`
  ${innerStyles}
`

const Layout = ({ children, quizType, path }) => (
  <>
    <Header path={path} />
    <StartQuizInfo quizType={quizType} />
    <GetStartedInfo />
    <Container>
      <main>{children}</main>
    </Container>
    <Footer />
    <GlobalStyles />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  quizType: PropTypes.string,
  path: PropTypes.string,
}

export default Layout
