import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import colors from "../utils/colors"
import { outerStyles, innerStyles, mobileWidth } from "../utils/common-styles"
import question from "../images/svg/question.svg"
import algorithm from "../images/svg/algorithm.svg"
import list from "../images/svg/list.svg"
import arrow from "../images/svg/arrow.svg"
import arrowDark from "../images/svg/arrow-dark.svg"

const Container = styled.section`
  ${outerStyles}
  background: ${colors.DARK_BLUE};
  @media (max-width: ${mobileWidth}px) {
    display: none;
  }
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 40px;
`

const Icons = styled.div`
  width: 720px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`

const Descriptions = styled.ul`
  width: 720px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin: 0 0 50px 0;
`

const Description = styled.li`
  width: 180px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${colors.WHITE};
  margin: 0;
`

// Max width of the icons
const IconContainer = styled.div`
  width: 70px;
  text-align: center;
`

const Icon = styled.img`
  margin-bottom: 20px;
`

const Title = styled.h2`
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: ${colors.WHITE};
  margin-bottom: 60px;
`

const LinkToGetStarted = styled(Link)`
  text-decoration: none;
  line-height: 30px;
  font-size: 14px;
  color: ${colors.WHITE};
  background: ${colors.GREEN};
  padding: 5px 30px;
  border-radius: 10px;
  &:hover {
    background ${colors.SAND};
  }
`

const GetStartedInfo = () => (
  <Container>
    <Inner>
      <Title>Erleichtert Ihnen die Suche nach Ihrem nächsten Tech-Talent!</Title>
      <Icons>
        <Icon src={arrowDark} alt="" />
        <IconContainer>
          <Icon src={question} alt="" />
        </IconContainer>
        <Icon src={arrow} alt="" />
        <IconContainer>
          <Icon src={algorithm} alt="" />
        </IconContainer>
        <Icon src={arrow} alt="" />
        <IconContainer>
          <Icon src={list} alt="" />
        </IconContainer>
        <Icon src={arrowDark} alt="" />
      </Icons>
      <Descriptions>
        <Description>
          Beantworten Sie einige kurze Fragen zu den Anforderungen an Ihren Entwickler
        </Description>
        <Description>
          Unser Algorithmus scannt in Sekundenschnelle verschiedene Optionen für die Anstellung eines geeigneten Kandidaten
        </Description>
        <Description>
          Erhalten Sie eine personalisierte Liste mit den besten Unternehmen, um einen Entwickler einzustellen
        </Description>
      </Descriptions>
    </Inner>
  </Container>
)

export default GetStartedInfo
